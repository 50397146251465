.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #3e5378;
  /* height: 57px; */
  width: 341px;
 padding-top: 10px;
 padding-bottom: 10px;
  position: fixed;
}

.iconButton {
  position: absolute;
  left: 0.25rem;
}

.title {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
}

.contentContainer {
  width: 341px;
  height: 600px;
  border-radius: 1rem;
  position: relative;
}

.loaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
}

.notificationContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.notificationText {
  font-family: Poppins !important;
  font-size: 0.8rem !important;
  /* font-weight: 600 !important; */
  letter-spacing: 0em;
  color: #3e5378;
}

.notificationTime {
  font-family: Poppins !important;
  font-size: 0.8rem !important;
  letter-spacing: 0em;
  color: #464a5390;
  margin-top: 0.15rem !important;
}

.dotBadge {
  margin-left: 16px;
  margin-right: 16px;
}

.emptyComponent {
  font-family: Poppins;
  font-size: 18px;
  color: #3e5378;
  padding-left: 1rem;
  padding-top: 1rem;
}

.loadMoreButton {
  width: 100%;
}