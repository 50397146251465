.root {
  background: #3e5378;
  color: rgb(255, 255, 255);
  height: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}
.logoWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  margin-top: 1.5rem;
}
.iconStyles {
  color: white;
}
.bottomTextCont {
  margin-top: auto;
}
.bottomTextStyles {
  font-size: 12px;
  font-family: "Poppins";
  color: white;
}

.mainContent {
  background: #e8e8e8;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding-top: 80px;
}
.hamIconStyles {
  height: 30px !important;
  width: 30px !important;
  color: #ffff;
  cursor: pointer;
}
.mobileDrawerHandler {
  display: none;
}
@media screen and (max-width: 1400px) {
  .mainContent {
    height: auto;
  }
}
/* @media screen and (max-width: 1280px) {
  .mainContent {
    margin-top: 4%;
  }
} */

@media screen and (max-width: 1199px) {
  .mobileDrawerHandler {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .root {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  /* .mainContent {
    margin-top: 7%;
  } */
}
@media screen and (max-width: 575px) {
  .mainContent {
    height: 100vh;
  }
  .logoWrap {
    margin-top: 0.5rem;
  }
}
