.mainGrid {
  width: 100%;
}
.formImg {
  /* width: 400px;
  height: 400px;
  margin-left: -50px;
  position: absolute;
  bottom: 0;
 */
 display: none;
}
.stepperBtn {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 50px;
  margin: 100px;
}
.naviBtn {
  background-color: #3e5378 !important;
  width: 160px;
  height: 42px;
  color: white !important;
  font-weight: 100 !important;
  text-transform: capitalize !important;
  cursor: pointer;
}

.imgCont {
  display: none;
}
/* button:disabled,
button[disabled] {
  background-color: #cccccc !important;
} */
.nameFeildDiv {
  display: flex;
  justify-content: center; 
  margin-top: 50px;
}
.OptionFeildLabel {
  color: #3e5378;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: -7px;
  padding-left: 23px;
  font-family: "Poppins" !important;
  text-align: left;
}
.checboxDiv{
  margin-left: 31px;
}
.checkInput {
  border: none;
  box-shadow: none !important;
  margin: 10px;
  margin-top: 30px;
  margin-left: 70px;
}
.checkBoxInput {
  padding: 16px;
  display: flex;
  flex-flow: column;
  width: 520px;
  margin: 10px;
  margin-top: 10px;
  background-color: #e8e8e8 !important;
}

@media screen and (max-width: 1400px) {
  .formImg {
    width: 300px;
    height: 300px;
  }
  .nameFeildDiv {
    margin-top: 30px;
  }
  .stepperBtn {
    /* margin-left: 125px !important; */
    margin: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .checkBoxInput{
    width: 420px;
  }
}

@media screen and (min-width: 1600px) {
  .imgCont {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .checkBoxInput{
    width: 395px !important;
  }
}
@media screen and (max-width: 575px) {
  .checkBoxInput{
    width: 313px !important;
  }
  .stepperBtn{
    margin: 15px;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 50px;
    margin: 50px;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: center;
    row-gap: 10px;
}

}