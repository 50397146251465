* {
  font-family: "Open Sans", sans-serif !important;
}
a {
  text-decoration: none;
}

.MuiInput-underline:before,
.Mui-focused {
  border-bottom: none !important;
}
.MuiBox-root .css-1gsv261 {
  border-bottom: none !important;
  display: flex;
  justify-content: center;
}
.MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  font-family: 700 !important;
}
.patientProfile .css-1pqm26d-MuiAvatar-img {
  object-fit: contain !important;
}
.basicInfo .css-1xhypcz-MuiStack-root .MuiTextField-root {
  width: 435px !important;
}
.basicInfo .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: #9499a1;
}
.contactGrid .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  border: none;
  background: none;
  padding: 20px;
  margin-top: -3px;
}
.swiper-slide {
  background: none !important;
}
.swiper-pagination-bullet-active {
  background: #60a7ae !important;
}

.client-checkbox {
  display: contents;
}
.addIcon .css-i4bv87-MuiSvgIcon-root {
  font-size: 2.25rem;
  color: white;
}
/* .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
} */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.swiper-slide {
  width: 80% !important;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}
.swiper-wrapper {
  transform: translate3d(0px, 0px, 0px);
}
#checbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #3e5378 !important;
}
#menu- .MuiList-root {
  display: grid !important;
}

.addIcon {
  position: relative;
  background: #3e5378;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin: 10px 0px 0px 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.mobileAppbar .css-ciy9n4-MuiPaper-root-MuiAppBar-root {
  background: #3e5378;
}
.mobileAppbar .css-jzk4qw-MuiPaper-root-MuiAppBar-root {
  background: #3e5378;
}
.mobileAppbar .css-yfo96e {
  margin-left: 0px;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background: #3e5378 !important;
}

#menu-time_zone .MuiPaper-root {
  width: 100px !important;
  padding: 10px;
  right: 215px;
}

.client-form .MuiInputBase-input {
  font-family: "Poppins" !important;
}
/* .password .MuiInputBase-input {
  padding: 13px 0px !important;
} */
.css-j3i6xr-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #fff0;
  border: none;
}

.MuiInput-underline:before,
.Mui-focused {
  border-bottom: 1px solid #c8c8c9 !important;
}

.MuiInput-underline::after,
.Mui-focused {
  border-bottom: none !important;
}

.css-187mznn-MuiSlider-root {
  border-radius: 28px !important;
  height: 35px !important;
}
.css-1gv0vcd-MuiSlider-track {
  border: none !important;
  height: 35px !important;
  background: linear-gradient(270deg, #60a7ae 8.28%, #7fcdcc 93.83%);
}
.css-14pt78w-MuiSlider-rail {
  background-color: #adadad !important;
}
.css-eg0mwd-MuiSlider-thumb {
  width: 40px !important;
  height: 40px !important;
  background-color: #ffffff !important;
}
.css-nnid7-MuiSlider-valueLabel {
  background-color: #60a7ae !important;
}
.MuiFormLabel-root {
  width: calc(100% - 0px);
  font-size: 1rem !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #5e5e5e !important;
  border-bottom: none !important;
  width: calc(133% - 0px) !important;
}

.overviewScreen .MuiFormLabel-root {
  color: #3e5378 !important;
  padding: 5px;
}
.resceduleContainer .css-1ps6pg7-MuiPaper-root {
  background-color: #f4f4f4 !important;
}
.overviewScreen
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: 2px #3e5378 solid;
}
.MuiInputLabel-shrink {
  width: calc(133% - 0px) !important;
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 0 !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiTabs-indicator {
  background-color: #3e5378 !important;
  height: 3px !important;
}
.signupScreen .MuiTextField-root {
  width: 60% !important;
}

.dropdownmargin .MuiFormLabel-root {
  margin-top: -23px !important;
}
.signupScreen .MuiInputBase-root {
  margin-top: 0 !important;
}
.upload-photo .MuiButtonBase-root {
  margin-top: 1rem;
}

.dashboard-client .fc-media-screen {
  height: 755px !important;
}

.loginScreen .MuiTextField-root {
  width: calc(100% - 0px) !important;
}

.resetPasswordScreen .MuiTextField-root {
  width: calc(100% - 0px) !important;
}

.resetPasswordScreen .MuiFormHelperText-root.Mui-error {
  text-align: center !important;
}

.forgetPassword .MuiFormHelperText-root.Mui-error {
  text-align: center !important;
}

.screeningQuestions .auth-stepper {
  display: flex;
  flex-wrap: wrap;
  /* padding-left: 0 !important; */
  justify-content: center;
  background: none;
}

.MuiDateRangeCalendar-root > div:first-child {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiTab-textColorSecondary {
  color: #3e5378 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

input[type="number"] {
  appearance: textfield; /* Firefox */
}
.MuiFormGroup-root {
  justify-content: center;
}
.MuiTypography-root {
  color: #3e5378;
}
.MuiRadio-root {
  color: #3e5378 !important;
}
/*  Questions */
.rightSection .MuiStepper-root {
  padding: 3rem 0 0 0 !important;
}

.auth-stepper .MuiStepper-root {
  padding: 3rem 0 0 0 !important;
}
.auth-stepper .MuiStepIcon-root.MuiStepIcon-active {
  color: #3e5378 !important;
}
.auth-stepper .MuiStep-horizontal {
  width: 18.5px !important;
  padding: 0 !important;
}
.auth-stepper .MuiStep-completed .QontoStepIcon-circle {
  color: #3e5378;
}
.auth-stepper .MuiStepIcon-text {
  display: none;
}
.auth-stepper .MuiStepIcon-completed {
  color: #3e5378 !important;
}
.auth-stepper .MuiStepConnector-root span {
  max-width: 50px !important;
}
.auth-stepper .MuiStepConnector-horizontal {
  max-width: 50px !important;
}
.MuiButtonBase-root-MuiTab-root {
  color: #3e5378 !important;
}

.profile .MuiTabs-indicator {
  display: none !important;
}
.password .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.MuiFormGroup-root {
  justify-content: center;
}

.MuiRadio-root {
  color: #3e5378 !important;
}
.appointment-list .date-range-picker {
  background: #f6f5f5;
  border-radius: 5px;
  height: 55px;
  margin-top: 4px;
}

.hover {
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .MuiTab-wrapper {
    font-size: 1.25rem;
  }
  .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
    margin-left: -80px;
    margin-top: 17px;
  }
}
@media screen and (max-width: 1280px) {
  .client-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    width: 410px;
  }
  .checkboxRelationship {
    margin-left: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .MuiTab-wrapper {
    font-size: 18px !important;
    font-weight: 600; /* optional, adjust as needed */
  }
  .MuiTabs-indicator {
    background-color: #3e5378 !important;
    /* width: 81px!important; */
    width: 60px !important;
    margin-left: 1rem;
    height: 3px !important;
  }
  .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    width: 210px !important;
    margin-left: 60px;
  }
  .screeningQuestions .auth-stepper {
    padding: 74px;
    margin-top: -40px;
    margin-bottom: -46px;
  }
}

@media screen and (max-width: 1024px) {
  .client-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    width: 395px;
  }
}

@media screen and (max-width: 768px) {
  .auth-stepper .MuiStepConnector-horizontal {
    min-width: 20px !important;
  }
  .auth-stepper .MuiStep-horizontal {
    width: unset !important;
    padding: 0 !important;
  }
  .auth-stepper .MuiStepLabel-iconContainer {
    padding: 0 !important;
  }
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }
}

.accordion .MuiAccordionSummary-expandIconWrapper {
  background-color: white !important;
  border-radius: 50%;
}

.tab-settings-layout {
  font-size: 0.95rem !important;
  color: #3e5378 !important;
  font-weight: 500 !important;
  margin: 0.2rem 1rem !important;
}

.tab-settings-layout {
  font-size: 0.95rem !important;
  color: #3e5378 !important;
  font-weight: 500 !important;
  margin: 0.2rem 1rem !important;
}

/* OTP FIELD */
.otpContainer {
  margin: 5% auto;
  display: flex;
  justify-content: center;
}

.otpInput {
  width: 3rem;
  height: 3rem;
  margin: 0 0.7rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 414px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
  .screeningQuestions .auth-stepper {
    padding: 40px;
    margin-top: -15px;
  }
}
@media screen and (max-width: 450px) {
}
@media only screen and (max-width: 575px) {
  .checkboxRelationship .MuiFormControlLabel-root {
    /* width: 400px !important; */
    display: none;
  }
  .otpInput {
    width: 2rem !important;
    height: 2rem;
    font-size: 1rem;
    padding: 8px;
    margin: 0 0.5rem;
  }
  .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-left: 0px;
    margin-top: 12px;
  }
  .provider-tabs .MuiTabs-indicator {
    margin-left: 4rem;
  }
  .css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons {
    display: flex !important;
  }
  .fc .fc-toolbar-title {
    font-size: 1.2rem;
    text-align: center;
  }
}
@media only screen and (max-width: 414px) {
  .otpInput {
    margin: 0 0.2rem;
  }
}

@media screen and (max-width: 1366px) {
  .customer-dashboard-calendar .fc-toolbar-title {
    font-size: 19px !important;
  }
  .otpInput{
    width: 2rem;
    height: 2rem;
    font-size: 1.4rem;
  }

}

/* .react-calendar__tile {
  background-color: white !important;
  color: #3e5378 !important;
} */

.react-calendar__tile--active {
  background-color: #3e5378 !important;
  color: white !important;
}
.react-calendar {
  box-shadow: none !important;
  /* background-color: white !important; */
  margin-top: 0px !important;
  border-radius: 8px;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border: 2px solid #3e5378 !important;
}
.checkboxRelationship .MuiFormControlLabel-root {
  width: 560px;
}

.checkboxChildren .MuiFormControlLabel-root {
  width: 560px;
}
.checkboxChildren .MuiFormControlLabel-root .MuiFormControlLabel-label {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ececec;
  color: #848484 !important;
}
.horiz-calendar .Mui-disabled {
  background-color: transparent !important;
}
.horiz-calendar .MuiButton-root {
  color: #3e5378 !important;
  background-color: transparent !important;
}
.pagination .Mui-selected {
  color: white !important;
  background-color: #3e5378 !important;
}
.custom-form-control {
  outline: none !important;
  border: none !important;
}

.customer-dashboard-calendar .fc-header-toolbar {
  background-color: #f1f5f8 !important;
  color: #3e5378 !important;
  padding: 10px !important;
}
.customer-dashboard-calendar .fc-button {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  border: none !important;
}
.customer-dashboard-calendar .fc-icon {
  color: #a2c0d4 !important;
}

.customer-dashboard-calendar .fc-toolbar-title {
  color: #3e5378;
  font-family: "Roboto" !important;
  font-size: 17px !important;
  font-weight: 500;
}

.customer-dashboard-calendar .fc-timegrid-slot-label-cushion {
  color: #3e5378 !important;
  font-family: "Rubik" !important;
}

.customer-dashboard-calendar .fc-event {
  background-color: transparent !important;
  border: none !important;
}

.customer-dashboard-calendar .fc-day {
  background-color: transparent !important;
}

.customer-dashboard-calendar .fc-popover-body {
  background-color: white !important;
}

.menu-item-signup {
  display: flex !important;
  justify-content: flex-start !important;
  text-align: left !important;
  white-space: pre-line !important;
}
.menu-item-reference-platform {
  padding: 0px 4px !important;
  display: flex !important;
  flex-flow: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

.drawer-container .MuiListItemButton-root {
  padding: 0;
}
.dashboard_calender .fc-timegrid-event-harness {
  max-height: 57px;
}
@media screen and (max-width: 575px) {
  .full-calender-wrap .fc-theme-standard td {
    height: 58px;
  }
  .full-calender-wrap .fc-view-harness-active {
    height: calc(100vh - 160px) !important;
  }

  .full-calender-wrap .fc .fc-view-harness {
    height: calc(100vh - 330px) !important;
  }
  .full-calender-wrap .fc-toolbar-chunk {
    width: 100%;
    text-align: center; /* Center the content */
    margin-bottom: 10px; /* Add spacing */
  }

  .full-calender-wrap .fc-toolbar-chunk .fc-button-group {
    display: flex;
    justify-content: center;
    gap: 10px; /* Add space between buttons */
    flex-wrap: wrap; /* Wrap buttons if needed */
  }

}
/* .MuiModal-root .MuiBox-root{
  width: calc(80% - 0) !important;
} */

/* Full-screen loader styling */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
}

/* Spinner styles */
.spinner {
  border: 4px solid #3e5378;
  border-top: 4px solid transparent; 
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.full-calender-wrap .fc .fc-view-harness {
  height: calc(100vh - 230px) !important;
}

.full-calender-wrap .fc-toolbar.fc-header-toolbar {
  flex-wrap: wrap; /* Allows wrapping */
}

.full-calender-wrap .fc-v-event {
  background-color: #ffffff;
  border: 1px dashed #2c3e50;
  display: block;
}