.client-form .PhoneInputInput {
    background-color: #e8e8e8;
    width: 440px;
    border: none;
    outline: none;
}

@media screen and (max-width: 1024px) {
    .client-form .PhoneInputInput {
        width: 334px !important;
    }
}

@media screen and (max-width: 1200px) {
    .client-form .PhoneInputInput {
        width: 335px;
    }
}

@media screen and (max-width: 575px) {
    .client-form .PhoneInputInput {
        width: 260px !important;
    }
}