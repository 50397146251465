.logo {
  height: 17vh;
  margin-bottom: -50px;
}
.img1 {
  height: 400px;
  width: 400px;
  margin-top: 50px;
  background-repeat: no-repeat;
}
.img2 {
  height: 300px;
  width: 300px;
  margin-top: 50px;
  background-repeat: no-repeat;
  margin-left: 40px;
}
.img3 {
  margin-left: 40px;
  margin-top: 50px;
  background-repeat: no-repeat;
}
.sliderDiv {
  margin-left: -117px;
}

.inputTextMargin {
  margin: 30px 0;
}

.errorStyle {
  font-weight: 600;
  display: block;
  font-size: 0.85rem;
  margin: 10px;
  color: red;
}
.mainGrid {
  height: 100vh;
}

.formDiv {
  margin-top: 200px;
}
.headlineMargin {
  margin: 20px;
}
.mainHeading {
  color: white;
  font-weight: 800;
  font-size: 3.1rem;
  line-height: 60px;
  width: 500px;
}
.innerdivlogin {
  width: 100%;
  display: flex;
  justify-content: center;
}
.thirdPara {
  color: #abb4bd;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  font-family: sans-serif;
  margin-top: 40px;
}

.fourthPara {
  color: #3e5378;
}

.introPara {
  color: white;
  font-style: normal;
  font-weight: 100;
  font-size: 1.1rem;
  line-height: 25px;
  font-family: sans-serif;
  margin-top: 0px;
  margin-left: 15px;
  text-align: left;
  width: 450px;
}

.secondHeading {
  color: #3e5378;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  width: 800px;
}
.secondPara {
  color: #60a7ae;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 27px;
  margin-top: -36px;
  margin-bottom: 66px;
}
.MuiInputBase-input:placeholder {
  text-align: center;
}

.tab {
  color: #3e5378 !important;
  font-size: 22px !important;
}
.loginBtn {
  background-color: #979797 !important;
  width: 160px;
  height: 42px;
  color: white !important;
  font-weight: 100 !important;
  margin-top: 40px !important;
  text-transform: capitalize !important;
}

.forgotBtn {
  background-color: #979797 !important;
  width: 200px;
  height: 42px;
  color: white !important;
  font-weight: 100 !important;
  margin-top: 40px !important;
  text-transform: capitalize !important;
}
.forgetBtn {
  display: flex;
  justify-content: flex-end;
}
.registerUserTypes {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkBoxContainer {
  width: calc(100% - 300px) !important;
  flex-direction: row !important;
  margin-top: 1rem;
}
.inputTextMargin label,
.checkBoxContainer label span {
  color: #abb4bd;
  font-weight: 500;
  margin-top: -9px;
  height: auto !important;
}

.registerUserTypeSelectionBox:nth-child(1) {
  background-color: #979797 !important;
  width: 238px;
  height: 50px;
  color: white !important;
  font-weight: 100 !important;
  margin-top: 40px !important;
  text-transform: capitalize !important;
  line-height: 48px;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
}

.MuiMenu-list {
  height: 300px !important;
}
.clientForm {
  display: flex;
  justify-content: center;
}
.formGrid {
  /* max-width: 100% !important; */
  background-color: white !important;
}

@media screen and (max-width: 1600px) {
  .secondHeading {
    font-size: 1.5rem;
    margin-bottom: 5px;
    width: 670px;
  }

  .secondPara {
    font-size: 1.3rem;
  }
  .mainHeading {
    font-size: 2.8rem;
    line-height: 50px;
    margin-top: 62px;
  }
  .img1 {
    width: 270px;
    height: 270px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 1500px) {
  .secondPara {
    font-size: 1.2rem;
  }
  .mainHeading {
    font-size: 2.6rem;
    line-height: 46px;
    width: 450px;
  }
}
@media screen and (max-width: 1366px) {
  .introPara {
    width: 400px;
  }
  .img1 {
    width: 300px;
    height: 300px;
    margin-top: -8px;
  }
  .img2 {
    width: 250px;
    height: 270px;
    margin-top: -8px;
  }
  .img3 {
    margin-top: 0px;
  }
  .logo {
    margin-bottom: -25px;
    margin-top: -32px;
  }
  .mainHeading {
    font-size: 2.5rem;
    line-height: 50px;
    margin-top: 33px;
  }
  .innerdivlogin {
    width: calc(100% - 400px);
    margin-top: 47px;
  }
  .introPara {
    font-size: 1rem;
    margin-left: 43px;
  }
  .formDiv {
    margin-top: 150px;
  }
  .inputTextMargin {
    margin: 10px 0;
    width: calc(100% - 200px) !important;
  }
  .secondHeading {
    width: 500px;
  }
}
@media screen and (max-width: 1280px) {
  .img1 {
    height: 250px;
    width: 250px;
  }
  .innerdivlogin {
    width: calc(100% - 400px);
    margin-top: -46px;
  }
  .mainHeading {
    line-height: 43px;
  }
}

@media screen and (max-width: 1024px) {
  .mainHeading {
    font-size: 2rem;
    line-height: 34px;
    margin-top: 40px;
    width: 350px;
  }
  .introPara {
    font-size: 0.9rem;
    line-height: 22px;
    width: 350px;
  }
  .img1 {
    height: 250px;
    width: 280px;
    margin-top: -11px;
  }
  .secondHeading {
    font-size: 1rem;
    margin-bottom: 10px;
    width: 500px;
  }
  .secondPara {
    font-size: 1.1rem;
    line-height: 10px;
  }
  .div1 {
    padding-left: 3rem;
  }
  .innerdivlogin {
    margin-top: 80px;
  }
}

@media screen and (max-width: 899px) {
  .sideBarDiv {
    position: unset !important;
  }
}

@media only screen and (max-width: 899px) and (min-width: 575px) {
  .sideBarDiv {
    margin: auto;
    width: 50%;
    padding: 10px;
    /* position: unset !important; */
  }
  .mainHeading {
    margin: auto;
    width: 99%;
    padding: 10px;
    text-align: center;
  }
  .sideBar {
    height: 70% !important;
  }
  .introPara {
    text-align: center;
    margin: auto;
    width: 99%;
    padding: 10px;
  }
  .img1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .sliderDiv {
    margin-left: auto;
  }
  .img2 {
    width: 200px;
    height: 200px;
    margin-top: 3px;
  }
  .img3 {
    margin-left: 18px;
    margin-top: 30px;
    background-repeat: no-repeat;
    width: 238px;
  }
  .formDiv {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .formGrid {
    margin-left: auto !important;
  }
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 575px) {
  .formGrid {
    margin-left: auto !important;
  }

  .mainHeading {
    font-size: 2rem;
    text-align: center;
    margin-top: 20px;
    margin: auto;
    width: 99%;
    padding: 10px;
  }
  .secondHeading {
    font-size: 0.85rem;
    margin: auto;
    width: 99%;
    padding: 10px;
  }

  .introPara {
    text-align: center;
    margin-top: 10px;
    font-size: 0.8rem;
    margin: auto;
    width: 99%;
    padding: 10px;
  }
  .img1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }
  .img2 {
    width: 200px;
    height: 200px;
    margin-top: 3px;
  }
  .img3 {
    margin-left: 18px;
    margin-top: 30px;
    background-repeat: no-repeat;
    width: 238px;
  }
  .sideBarDiv {
    width: auto !important;
  }
  .sliderDiv {
    margin-left: auto;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .btnDiv {
    margin-bottom: 60px;
  }
  .innerdivlogin {
    width: calc(100% - 15px);
  }
  .inputTextMargin {
    width: calc(100% - 110px) !important;
  }
  .sideBar {
    height: auto !important;
    /* width: 100%; */
  }
  .formDiv {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 414px) {
  .mainHeading {
    font-size: 1.9rem;
    margin: auto;
    width: 99%;
    padding: 10px;
  }
  .introPara {
    line-height: 17px;
    margin: auto;
    width: 99%;
    padding: 10px;
  }
  .secondHeading {
    margin: auto;
    width: 99%;
    padding: 10px;
  }
  .img1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 240px;
  }
  .formDiv {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .formGrid {
    margin-left: auto !important;
  }
  .sideBar {
    height: auto !important;
    position: inherit !important;
  }
}
/* new css */

.sideBar {
  background: #3e5378;
}
.sideBarDiv {
  padding: 60px;
  position: fixed;
  width: 50%;
}
/* .formGrid {
  margin-left: 50%;
  display: flex;
  justify-content: center;
} */
@media only screen and (max-width: 3000px) and (min-width: 900px) {
  .sideBar {
    background: #3e5378;
    /* height: 100%;
    width: 100%;
    position: fixed; */
  }
}
