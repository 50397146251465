.headingDiv {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.headingDiv h2 {
  color: #3e5378;
  text-align: center;
  font-size: 1.3rem;
  width: 800px;
}
.feildLabel {
  color: #3e5378;
  font-weight: 600;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: -7px;
  padding-left: 10px;
  font-family: "Poppins" !important;
}
.OptionFeildLabel {
  color: #3e5378;
  font-weight: 600;
  /* text-align: left; */
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: -7px;
  padding-left: 23px;
  font-family: "Poppins" !important;
  text-align: left;
}
.AnswerFeildLabel {
  color: #3e5378;
  font-weight: 600;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: -7px;
  padding-left: 10px;
  font-family: "Poppins" !important;
  width: 500px;
}
.nameFeildDiv {
  display: flex;
  justify-content: center;
}

.phoneInput {
  padding: 2px 20px;
  display: flex;
  width: 520px;
  height: 50px;
  margin: 10px;
  margin-top: 10px;
  background-color: #e8e8e8 !important;
}

.nameInput {
  padding: 2px 8px;
  display: flex;
  flex-flow: column;
  width: 250px;
  height: 50px;
  margin: 10px;
  background-color: #e8e8e8 !important;
}
.otherInput {
  padding: 2px 20px;
  display: flex;
  flex-flow: column;
  width: 520px;
  height: 50px;
  margin: 10px;
  margin-top: 10px;
  background-color: #e8e8e8 !important;
}

.timeZoneInput {
  padding: 2px 20px;
  display: flex;
  flex-flow: column;
  width: 520px;
  height: 50px;
  margin: 10px;
  margin-top: 10px;
  /* background-color: #e8e8e8 !important; */
  box-shadow: none !important;
}
.checkBoxInput {
  padding: 16px;
  display: flex;
  flex-flow: column;
  width: 520px;
  margin: 10px;
  margin-top: 10px;
  background-color: #e8e8e8 !important;
}
.checkInput {
  border: none;
  box-shadow: none !important;
  margin-top: 30px;
}
.checboxDiv{
  margin-left: 31px;
}
.selectedGender {
  background-color: #3e5378 !important;
  text-transform: capitalize !important;
  width: 160px !important;
  color: #fff !important;
  height: 40px;
  border-radius: 4px !important;
}

.unselectedGender {
  color: #3e5378 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  width: 160px !important;
}
.genderBtn {
  gap: 30px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}
/* @media screen and (max-width: 575px) {
  .nameInput {
    width: 300px;
  }
  .otherInput {
    width: 620px;
  }
  .checkBoxInput{
    width: 620px;
  }
} */

.naviBtn {
  background-color: #3e5378 !important;
  color: white !important;
  font-weight: 100 !important;
  text-transform: capitalize !important;
  cursor: pointer;
  padding: 5px 30px !important;
  border-radius: 8px !important;
}
.clearFormBtn {
  color: #3e5378 !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer;
  border-bottom: 1px solid #3e5378 !important;
  height: 40px !important;
  margin-right: 30px !important;
}
/* button:disabled,
button[disabled] {
  background-color: #cccccc !important;
} */
.stepperBtn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 50px;
  margin: 50px;
  gap: 50px;
  padding-inline-end: 135px
}
@media screen and (max-width: 1500px) {
  .stepperBtn{
    padding-inline-end: 0px;
  }
}
@media screen and (max-width: 1024px) {
  .headingDiv h2 {
    font-size: 1.1rem;
  }
  .nameInput {
    width: 185px !important;
  }
  .otherInput {
    width: 395px !important;
  }

  .phoneInput {
    width: 395px !important;
  }

  .checkBoxInput {
    width: 395px !important;
  }

  .selectedGender {
    height: 40px;
  }
  .unselectedGender {
    height: 40px;
    font-size: 14px !important;
  }
  .OptionFeildLabel {
    /* margin-left: 65px */
  }
  .AnswerFeildLabel {
    margin-left: 65px;
  }
}
@media screen and (max-width: 1200px) {
  .nameInput {
    width: 200px;
  }
  .otherInput {
    width: 420px;
  }

  .phoneInput {
    width: 420px;
  }

  .checkBoxInput {
    width: 420px;
  }

  .selectedGender {
    height: 40px;
  }
  .unselectedGender {
    height: 40px;
  }
}
@media screen and (max-width: 575px) {
  .stepperBtn {
    margin: 15px;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 50px;
    margin: 50px;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: center;
    row-gap: 10px;
  }
  .nameInput {
    width: 150px !important;
    padding: 0px;
  }
  .otherInput {
    width: 330px !important;
    padding: 0px;
  }

  .phoneInput {
    width: 330px !important;
    padding: 0px;
  }

  .checkBoxInput {
    width: 317px !important;
  }
  .OptionFeildLabel {
    /* display: none; */
  }
  .AnswerFeildLabel {
    margin-left: 3px;
    width: 350px;
  }
  .genderBtn {
    gap: 0px;
    padding: 5px;
  }
  .lastname_errorMsg {
    width: 150px;
  }
  .emergContact_errorMsg {
    width: 350px;
  }
}
