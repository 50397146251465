.parent {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.box {
  margin-right: 30px;
  display: flex;
}

.colorWHite {
  color: white;
}

.dashboardTextStyles {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: #3e5378;
  line-height: 1;
  /* margin-right: 1rem; */
}

.chatAndForwardWrapperCont {
  background: #f6f6f6;
  border-radius: 10px;
  height: 56px;
  width: 56px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.yesBtnStyles {
  background-color: red !important;
  color: white !important;
  margin-right: 20px !important;
}

.noBtnStyles {
  background-color: #60a7ae !important;
  color: white !important;
}
.text2Styles {
  font-size: 20px;
  color: #3e5378;
  font-weight: 500;
}
.btnsCont {
  display: flex;
  justify-content: flex-end;
}


.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 29px;
  object-fit: cover;
  object-position: center;
}

.hamIconStyles {
  height: 30px !important;
  width: 30px !important;
  color: #3e5378;
  margin-right: 30px;
  cursor: pointer;
}
.areYouSureModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #FFF;
  box-shadow: 24;
  padding: 25px;
  border-radius: 12px;
  border: none !important;
  outline: none !important;
}


@media screen and (max-width: 575px) {
  .dashboardTextStyles{
    font-size: 1.3rem;
  }
  .chatAndForwardWrapperCont{
    height: 40px;
    width: 40px;
    border-radius: 6px;
  }
  .avatar{
    height: 40px;
    width: 40px;
  }
  .box{
    margin-right: 0px;
  }
  .areYouSureModal{
    width: 367px;
  }
  .hamIconStyles {
    margin-right: 10px;
  }
}