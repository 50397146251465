.iconStyles {
  color: #ffffff;
}

.listStyles {
}

.logoStyles {
}

.fontStyles {
  font-family: "Open Sans";
  font-weight: 400;
  color: #ffffff;

}

.listItem {
  margin-top: -5px !important;
  padding-bottom: 4px;
  padding-top: 4px;
}

.providerListItem {
  padding: 0px !important;
  margin-top: -30px !important;
}

.providerFontStyles {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
}

@media screen and (max-width: 575px) {
  /* .fontStyles {
    font-size: 1.7rem;
  } */
}